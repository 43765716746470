import { useSprings, useSpring, animated, config } from 'react-spring'
import { useKeenSlider } from 'keen-slider/react'
import { clamp, lerp } from '@kaliber/math'
import { useTranslate } from '/machinery/I18n'
import { trackDragStart, trackDragEnd, trackNavigate } from '/machinery/tracking/trackCarousel'
import { ImageCropped } from '/features/buildingBlocks/Image'
import { ButtonArrowRight, ButtonArrowLeft } from '/features/buildingBlocks/Button'

import styles from './ImageCarousel.css'

export function ImageCarouselSquare({ images, layoutClassName = undefined }) {
  const aspectRatio = 1 / 1

  return (
    <ImageCarouselBase
      className={layoutClassName}
      {...{ images, aspectRatio }}
    />
  )
}

export function ImageCarouselLandscape({ images, layoutClassName = undefined }) {
  return (
    <ImageCarouselBase
      className={layoutClassName}
      {...{ images }}
    />
  )
}

function ImageCarouselBase({ aspectRatio = undefined, images, className }) {
  const title = 'Image carousel'
  const { __ } = useTranslate()

  const [slide, setSlide] = React.useState(0)
  const [springs, springsApi] = useSprings(images.length, i => ({
    x: calculateX(i),
    rotateZ: calculateRotation(i),
    opacity: calculateOpacity(i)
  }))

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: images.length, // By passing a number, keen-slider expects us to update the UI
    detailsChanged: handleDrag,
    dragStarted(e) { trackDragStart({ title, index: e.track.details.rel }) },
    dragEnded(e) { trackDragEnd({ title, index: e.track.details.rel }) },
    slideChanged(e) { setSlide(e.track.details.rel) }
  })

  // Make keen-slider work with server-side rendering ¯\_(ツ)_/¯
  React.useEffect(
    () => { instanceRef.current.update() },
    [instanceRef]
  )

  return (
    <div className={cx(styles.componentBase, className)}>
      <div ref={sliderRef} className={styles.carousel}>
        {images.map((x, i) => (
          <animated.div key={x._key} className={styles.slide} style={{
            x: springs[i].x.to(calculateX),
            opacity: springs[i].x.to(calculateOpacity),
            rotateZ: springs[i].x.to(calculateRotation),
            zIndex: images.length - i
          }}>

            { aspectRatio && (
              <ImageCropped image={x} {...{ aspectRatio }} />
            )}
            { !aspectRatio && (
              <>
                <ImageCropped image={x} aspectRatio={4 / 5} layoutClassName={styles.imageMobile} />
                <ImageCropped image={x} aspectRatio={16 / 9} layoutClassName={styles.imageDesktop} />
              </>
            )}
          </animated.div>
        ))}
      </div>

      <ButtonWrapper visible={slide < images.length - 1} layoutClassName={styles.next}>
        <ButtonArrowRight dataX='goto-next-slide' onClick={nextSlide}>
          {__`image-carousel-next`}
        </ButtonArrowRight>
      </ButtonWrapper>

      <ButtonWrapper visible={slide !== 0} layoutClassName={styles.previous}>
        <ButtonArrowLeft dataX='goto-previous-slide' onClick={previousSlide}>
          {__`image-carousel-previous`}
        </ButtonArrowLeft>
      </ButtonWrapper>
    </div>
  )

  function handleDrag(e) {
    const { slides } = e.track.details

    springsApi.start(i => ({
      x: slides[i].distance,
      immediate: true
    }))
  }

  function nextSlide() {
    instanceRef.current.next()
    trackNavigate({ title, index: slide + 1 })
  }

  function previousSlide() {
    instanceRef.current.prev()
    trackNavigate({ title, index: slide - 1 })
  }
}

function ButtonWrapper({ visible, children, layoutClassName }) {
  const spring = useSpring({
    opacity: visible ? 1 : 0,
    scale: visible ? 1 : 0.5,
    config: config.wobbly
  })

  return (
    <animated.div style={spring} className={layoutClassName} aria-hidden={!visible}>
      {children}
    </animated.div>
  )
}

function calculateX(n) {
  return Math.min(0, n * 100) + '%'
}

function calculateRotation(n) {
  const input = 1 - clamp({ min: 0, max: 1, input: 1 + n })
  return lerp({ start: 0, end: -6, input }) + 'deg'
}

function calculateOpacity(n) {
  const number = parseFloat(n)
  const input = 1 - clamp({ min: 0, max: 1, input: 1 + number })
  return lerp({ start: 10, end: 0, input })
}
